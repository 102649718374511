import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { createBrowserHistory } from "history";
import React, { useContext, useLayoutEffect } from "react";
import { Redirect, Router, Switch } from "react-router-dom";
import LoadingOverlay from "./Components/Common/LoadingOverlay";
import PrivateRoute from "./Components/Common/PrivateRoute";
import PublicRoute from "./Components/Common/PublicRoute";
import HighLoadDialog from "./Components/HighLoadDialog";
import BrandPage from "./Components/Tools/Brand/BrandPage/BrandPage";
import { getCurrentRoute, isDisableLoaderRoute, isLightThemeRoute } from "./Components/Tools/utilities";
import UploadDialog from "./Components/UploadDialog";
import LandingPageConstants from "./Constants/LandingPageConstants";
import RouterConstants from "./Constants/RouterConstants";
import AppContext from "./Context/Context";
import Analytics from "./Models/Analytics";
import SelectedPage from "./Models/SelectedPage";
import AuthDialog from "./Pages/Authentication/AuthDialog";
import BriaPolicy from "./Pages/BriaPolicy";
import GaiaDemo from "./Pages/PreLoginPage/gaiaDemo";
import Iframe from "./Pages/Iframe";
import LandingPage from "./Pages/LandingPage";
import LandingPage2 from "./Pages/LandingPage2";
import LandingPage3 from "./Pages/LandingPage3";
import Campain from "./Pages/Campain";
import EarlyAccess from "./Pages/pricing/EarlyAccess";
import FairDiffusion from "./Pages/pricing/FairDiffusionPage";
import { DBName } from "./types/graphql-global-types";
import User from "./Models/User";
import { IsIframe, sendPostMessage } from "./Helpers/iframe";
import { IframePostMessageTypes } from "./Constants/IframeConstants";
import ApiPricingComponent from "./Pages/ApiPricing/ApiPricingComponent";
import { IsGuestUser } from "./Helpers/isGuest";
import { PagesEnum } from "./Models/ToolsConfig";
import { ToastContainer } from "react-toastify";

const HomePage = React.lazy(() => import(/* webpackChunkName: "[LandingHomePage]" */ "./Pages/LandingHomePage"));

const EarlyBird = React.lazy(() => import(/* webpackChunkName: "[EarlyBird]" */ "./Pages/EarlyBird"));

const PageNotFound = React.lazy(() => import(/* webpackChunkName: "[PageNotFound]" */ "./Pages/PageNotFound"));

const MobilePage = React.lazy(() => import(/* webpackChunkName: "[MobilePage]" */ "./Pages/MobilePage"));

const LoginPage = React.lazy(() => import(/* webpackChunkName: "[LoginPage]" */ "./Pages/LoginPage"));

const RegisterPage = React.lazy(() => import(/* webpackChunkName: "[RegisterPage]" */ "./Pages/RegisterPage"));

const RegisterPageViewers = React.lazy(
	() => import(/* webpackChunkName: "[RegisterPageViewers]" */ "./Pages/RegisterPageViewers")
);

const ForgotPasswordPage = React.lazy(
	() => import(/* webpackChunkName: "[ForgotPasswordPage]" */ "./Pages/ForgotPasswordPage")
);

const VerifyAccountPage = React.lazy(
	() => import(/* webpackChunkName: "[VerifyAccountPage]" */ "./Pages/VerifyAccountPage")
);

const UserInfoPage = React.lazy(() => import(/* webpackChunkName: "[UserInfoPage]" */ "./Pages/UserInfoPage"));

const Authentication = React.lazy(() => import(/* webpackChunkName: "[AuthPage]" */ "./Pages/Auth"));

const AppHeader = React.lazy(() => import(/* webpackChunkName: "[AppHeader]" */ "./Components/AppHeader"));

const SearchForImage = React.lazy(
	() => import(/* webpackChunkName: "[SearchForImage]" */ "./Pages/Tool/SearchForImage/SearchForImage")
);

const SearchForImageDemo = React.lazy(
	() => import(/* webpackChunkName: "[SearchForImage]" */ "./Pages/Tool/SearchForImage/SearchForImageDemo")
);

const ImageEditTool = React.lazy(() => import(/* webpackChunkName: "[ImageEditTool]" */ "./Pages/Tool/ImageEditor"));

const ImageReview = React.lazy(() => import(/* webpackChunkName: "[ImageReview]" */ "./Pages/ImageReview"));

const ImageReviewResearch = React.lazy(
	() => import(/* webpackChunkName: "[ImageReview]" */ "./Pages/ImageReviewResearch")
);

const InstanceManagement = React.lazy(
	() => import(/* webpackChunkName: "[InstanceManagement]" */ "./Pages/InstanceManagement")
);

const ToolHeader = React.lazy(() => import(/* webpackChunkName: "[ToolHeader]" */ "./Components/Tools/ToolHeader"));
const ToolHeaderDemo = React.lazy(
	() => import(/* webpackChunkName: "[ToolHeader]" */ "./Components/Tools/ToolHeaderDemo")
);

const ImageViewer = React.lazy(() => import(/* webpackChunkName: "[ImageViewer]" */ "./Pages/ImageViewer"));

const Organization = React.lazy(() => import(/* webpackChunkName: "[Organization]" */ "./Pages/Organization"));

const UserManagement = React.lazy(() => import(/* webpackChunkName: "[UserManagement]" */ "./Pages/UserManagement"));

const APIBillingDashboard = React.lazy(
	() => import(/* webpackChunkName: "[APIBillingDashboard]" */ "./Pages/APIBillingDashboard")
);

const AssetsManagement = React.lazy(
	() => import(/* webpackChunkName: [AssetsManagement] */ "./Pages/AssetsManagement")
);

const DekaPage = React.lazy(() => import(/* webpackChunkName: "[DekaPage]" */ "./Pages/DekaPortal/DekaPage"));

const FeedbackDialog = React.lazy(() => import("./Components/FeedbackDialog"));
const DemoApplicationDialog = React.lazy(() => import("./Components/DemoApplicationDialog"));

const history = createBrowserHistory({
	basename: `/${process.env.REACT_APP_BASENAME ?? ""}`,
});
history.listen(() => {
	Analytics.fireScreenView();
});

export enum ROLES {
	ADMIN = "admin",
	USER = "view",
	EXTERNAL = "external",
	NON = "non",
}

const ALL_USERS = [ROLES.ADMIN, ROLES.USER, ROLES.EXTERNAL];

export enum PAGE_TYPES {
	PRIVATE = "PRIVATE",
	PUBLIC = "PUBLIC",
}

const AdminRole = () => [ROLES.ADMIN];

const hasOrganization = (user: User): boolean => {
	return user.organizations.length > 0;
};

function PageContianer({ children, fullHeight }: React.PropsWithChildren<any>) {
	const classes = useStyles();
	return (
		<React.Suspense
			fallback={
				<Box width="100vw" display="flex" justifyContent="center" alignItems="center">
					<CircularProgress />
				</Box>
			}
		>
			<main className={classes.content}>
				<div className={clsx(classes.toolbar)} style={{ height: fullHeight ? "100%" : "auto" }}>
					{children}
				</div>
			</main>
		</React.Suspense>
	);
}

function Routes({
	setIsLightThemeRoute,
	setDisableLoaderRoute,
}: {
	setIsLightThemeRoute: (value: boolean) => void;
	setDisableLoaderRoute: (value: boolean) => void;
}) {
	const classes = useStyles();
	const context = useContext(AppContext);
	const queryParams = new URLSearchParams(window ? window.location.search : {});
	const iframeImageVhash = queryParams.get("vhash");
	const iframeId = queryParams.get("iframeId");
	const { isGuestUser } = IsGuestUser();
	history.listen((location) => {
		setIsLightThemeRoute && setIsLightThemeRoute(isLightThemeRoute());
		setDisableLoaderRoute && setDisableLoaderRoute(isDisableLoaderRoute());
	});
	const { isIframe } = IsIframe();
	useLayoutEffect(
		() =>
			history.listen((location) => {
				const currentRoute = getCurrentRoute();
				isIframe() &&
					sendPostMessage(
						IframePostMessageTypes.iframePageNavigation,
						{
							type: "navigation",
							page: currentRoute.name,
							path: window.location.pathname + window.location.search + window.location.hash,
						},
						"",
						context.iframeConfig
					);
			}),
		[history]
	);

	return (
		<>
			<Router history={history}>
				<div className={classes.root}>
					<FeedbackDialog />
					<DemoApplicationDialog />
					<UploadDialog />
					<AuthDialog />
					<HighLoadDialog />
					<Switch>
						{iframeId &&
							(context.iframeConfig ? (
								<>
									{context.iframeConfig?.target === PagesEnum.Assets ? (
										<Redirect to={`${RouterConstants.ASSETS_PAGE.path}`} />
									) : context.iframeConfig?.target === PagesEnum.Gallery ? (
										<Redirect to={`${RouterConstants.GALLERY.path}`} />
									) : context.iframeConfig?.target === PagesEnum.Playground && iframeImageVhash ? (
										<Redirect to={`${RouterConstants.GALLERY.path}${iframeImageVhash}`} />
									) : context.iframeConfig?.target === PagesEnum.Campaign ? (
										<Redirect to={`${RouterConstants.campaignGeneration.path}`} />
									) : (
										<Redirect to={`${RouterConstants.PAGE_NOT_FOUND.path}`} />
									)}
								</>
							) : (
								<LoadingOverlay />
							))}

						<PublicRoute path={RouterConstants.VERIFY.path} exact={RouterConstants.VERIFY.exact}>
							<Box width="100%">
								<VerifyAccountPage />
							</Box>
						</PublicRoute>

						<PublicRoute path={RouterConstants.AUTH.path} exact={RouterConstants.AUTH.exact}>
							<Box width="100%">
								<Authentication />
							</Box>
						</PublicRoute>
						<PublicRoute
							path={RouterConstants.GAIA_PRELOGIN.path}
							exact={RouterConstants.GAIA_PRELOGIN.exact}
						>
							<Box width="100%">
								<GaiaDemo />
							</Box>
						</PublicRoute>

						<PublicRoute path={RouterConstants.EARLY_BIRD.path}>
							<Box width="100%">
								<EarlyBird />
							</Box>
						</PublicRoute>

						<PublicRoute path={RouterConstants.LANDING_PAGE.path}>
							<Box width="100%" height="100vh">
								<LandingPage />
							</Box>
						</PublicRoute>

						<PublicRoute path={RouterConstants.API_LANDING.path}>
							<Box width="100%" height="100vh">
								<LandingPage3 constants={LandingPageConstants.DEFAULT} />
							</Box>
						</PublicRoute>

						<PublicRoute path={RouterConstants.SMART_SEARCH_LANDING_PAGE.path}>
							<Box width="100%" height="100vh">
								<LandingPage2 constants={LandingPageConstants.SMART_SEARCH} />
							</Box>
						</PublicRoute>
						<PublicRoute path={RouterConstants.REMOVE_BG_LANDING_PAGE.path}>
							<Box width="100%" height="100vh">
								<LandingPage2 constants={LandingPageConstants.REMOVE_BG} />
							</Box>
						</PublicRoute>
						<PublicRoute path={RouterConstants.ADD_LOGO_LANDING_PAGE.path}>
							<Box width="100%" height="100vh">
								<LandingPage2 constants={LandingPageConstants.ADD_LOGO} />
							</Box>
						</PublicRoute>
						<PublicRoute path={RouterConstants.TERMS_OF_USE.path}>
							<Box width="100%">
								<BriaPolicy
									title="Terms of service"
									src="https://labs-assets.bria.ai/BriaTermsofUseDoc.html"
								/>
							</Box>
						</PublicRoute>
						<PublicRoute path={RouterConstants.API_TERMS_OF_USE.path}>
							<Box width="100%">
								<BriaPolicy
									title="Api Terms of service"
									src="https://labs-assets.bria.ai/BriaAPITermsofUseDoc.html"
								/>
							</Box>
						</PublicRoute>
						<PublicRoute path={RouterConstants.PRIVACY_POLICY.path}>
							<Box width="100%">
								<BriaPolicy
									title="Privacy Policy"
									src="https://labs-assets.bria.ai/BriaPrivacyPolicyDoc.html"
								/>
							</Box>
						</PublicRoute>
						<PublicRoute path={RouterConstants.REFUND_POLICY.path}>
							<Box width="100%">
								<BriaPolicy
									title="Refund Policy"
									src="https://labs-assets.bria.ai/BriaRefundPolicyDoc.html"
								/>
							</Box>
						</PublicRoute>
						<PublicRoute
							path={RouterConstants.PAGE_NOT_FOUND.path}
							exact={RouterConstants.PAGE_NOT_FOUND.exact}
						>
							<Box width="100%">
								<PageNotFound />
							</Box>
						</PublicRoute>

						<PublicRoute path={RouterConstants.USER_INFO.path} exact={RouterConstants.USER_INFO.exact}>
							<Box width="100%">
								<UserInfoPage />
							</Box>
						</PublicRoute>

						<PublicRoute path={RouterConstants.LOGIN.path} exact={RouterConstants.LOGIN.exact}>
							{isGuestUser() ? (
								<Box width="100%">
									<LoginPage />
								</Box>
							) : (
								<Redirect to={RouterConstants.HOME.path} />
							)}
						</PublicRoute>

						<PublicRoute path={RouterConstants.SIGNUP.path} exact={RouterConstants.SIGNUP.exact}>
							{isGuestUser() ? (
								<Box width="100%">
									<RegisterPage />
								</Box>
							) : (
								<Redirect to={RouterConstants.HOME.path} />
							)}
						</PublicRoute>

						<PublicRoute
							path={RouterConstants.SIGNUPVIEWERS.path}
							exact={RouterConstants.SIGNUPVIEWERS.exact}
						>
							<Box width="100%">
								<RegisterPageViewers />
							</Box>
						</PublicRoute>

						<PublicRoute
							path={RouterConstants.FORGOTPASSWORD.path}
							exact={RouterConstants.FORGOTPASSWORD.exact}
						>
							<Box width="100%">
								<ForgotPasswordPage />
							</Box>
						</PublicRoute>

						<PublicRoute exact={RouterConstants.MOBILE_PAGE.exact} path={RouterConstants.MOBILE_PAGE.path}>
							<MobilePage />
						</PublicRoute>
						<PublicRoute
							exact={RouterConstants.FAIR_DIFFUSION.exact}
							path={RouterConstants.FAIR_DIFFUSION.path}
						>
							<FairDiffusion />
						</PublicRoute>

						<PublicRoute
							exact={RouterConstants.EARLY_ACCESS.exact}
							path={RouterConstants.EARLY_ACCESS.path}
						>
							<EarlyAccess />
						</PublicRoute>

						<PublicRoute path={RouterConstants.HOME.path} exact={RouterConstants.HOME.exact}>
							<HomePage />
						</PublicRoute>
						<PublicRoute path={RouterConstants.GALLERY_VH.path} exact={RouterConstants.GALLERY_VH.exact}>
							<Box height="100%" width="100vw" key={`gallery-vh-${context.systemView}`}>
								<ImageEditTool />
							</Box>
						</PublicRoute>
						<PublicRoute exact={RouterConstants.GALLERY.exact} path={RouterConstants.GALLERY.path}>
							<Box
								// display="grid"
								minHeight="100vh"
								width="100vw"
								gridTemplateRows="80px auto"
								key={`gallery-${context.systemView}`}
							>
								{(!isIframe() || (IsIframe() && !context.iframeConfig?.embeddedIframe)) && (
									<ToolHeader isGallery={true} />
								)}
								<SearchForImage />
							</Box>
						</PublicRoute>
						<PublicRoute exact={RouterConstants.ASSETS_PAGE.exact} path={RouterConstants.ASSETS_PAGE.path}>
							{/*<HasCredits creditType={SubscriptionCreditsTypes.MY_ASSETS}>*/}
							<AssetsManagement />
							{/*</HasCredits>*/}
						</PublicRoute>
						<PublicRoute
							exact={RouterConstants.campaignGeneration.exact}
							path={RouterConstants.campaignGeneration.path}
						>
							<Campain />
						</PublicRoute>
						{/*<PublicRoute exact={RouterConstants.WEB_PLANS.exact} path={RouterConstants.WEB_PLANS.path}>*/}
						{/*	<PricingV2 />*/}
						{/*</PublicRoute>*/}
						<PublicRoute exact={RouterConstants.API_PLANS.exact} path={RouterConstants.API_PLANS.path}>
							<ApiPricingComponent isMarketingFlag={true} />
						</PublicRoute>
						<PublicRoute
							exact={RouterConstants.MARKETING_PLANS.exact}
							path={RouterConstants.MARKETING_PLANS.path}
						>
							<ApiPricingComponent isMarketingFlag={false} />
						</PublicRoute>

						{/* Private Routes */}
						<PublicRoute
							path={RouterConstants.GALLERY_DEMO.path}
							exact={RouterConstants.GALLERY_DEMO.exact}
						>
							<Box
								// display="grid"
								minHeight="100vh"
								width="100vw"
								gridTemplateRows="80px auto"
								key={`gallery-${context.systemView}`}
							>
								<ToolHeaderDemo isGallery={true} />
								<SearchForImageDemo />
							</Box>
						</PublicRoute>

						<PublicRoute
							path={RouterConstants.SAMSUNG_DEMO.path}
							exact={RouterConstants.SAMSUNG_DEMO.exact}
						>
							<Box
								// display="grid"
								minHeight="100vh"
								width="100vw"
								gridTemplateRows="80px auto"
								key={`gallery-${context.systemView}`}
							>
								<ToolHeaderDemo isGallery={true} isSamsung={true} />
								<SearchForImageDemo isSamsung={true} />
							</Box>
						</PublicRoute>

						<PublicRoute
							path={RouterConstants.SAMSUNG_SKETCH_DEMO.path}
							exact={RouterConstants.SAMSUNG_SKETCH_DEMO.exact}
						>
							<Box
								// display="grid"
								minHeight="100vh"
								width="100vw"
								gridTemplateRows="80px auto"
								key={`gallery-${context.systemView}`}
							>
								<ToolHeaderDemo isGallery={true} isSamsung={true} />
								<SearchForImageDemo isSamsung={true} isSamsungSketch={true} />
							</Box>
						</PublicRoute>

						<PrivateRoute
							exact={RouterConstants.IFRAME.exact}
							path={RouterConstants.IFRAME.path}
							roles={AdminRole()}
						>
							<AppHeader title={"Iframe"} />
							<PageContianer fullHeight={true}>
								<Iframe
									iframeId={queryParams.get("iframeIdInput") ?? undefined}
									vhash={queryParams.get("vhash") ?? undefined}
									enabledFeatures={queryParams.get("enabledFeatures") ?? undefined}
									enableDownload={queryParams.get("enableDownload") == "true"}
									usageText={queryParams.get("usageText") ?? undefined}
									sessionId={queryParams.get("sessionId") ?? undefined}
									userId={queryParams.get("userId") ?? undefined}
									embeddedIframe={queryParams.get("embeddedIframe") == "true"}
									disableDownloadPsd={queryParams.get("disableDownloadPsd") == "true"}
									disableSave={queryParams.get("disableSave") == "true"}
									disableSavePsd={queryParams.get("disableSavePsd") == "true"}
									saveLabel={queryParams.get("saveLabel") ?? undefined}
									disableDownloadImage={queryParams.get("disableDownloadImage") == "true"}
									selectedTab={queryParams.get("selectedTab") ?? undefined}
								/>
							</PageContianer>
						</PrivateRoute>
						<PrivateRoute
							exact={RouterConstants.BRAND_PAGE.exact}
							path={RouterConstants.BRAND_PAGE.path}
							roles={AdminRole()}
						>
							<BrandPage />
						</PrivateRoute>
						<PrivateRoute
							exact={RouterConstants.VDR_ID.exact}
							path={RouterConstants.VDR_ID.path}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.IMAGEREPOSITORY} />
							<PageContianer>
								<Box>
									<ImageReview dbName={DBName.VDR} />
								</Box>
							</PageContianer>
							{/* <Copyright /> */}
						</PrivateRoute>

						<PrivateRoute
							path={RouterConstants.VDR.path}
							exact={RouterConstants.VDR.exact}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.IMAGEREPOSITORY} />
							<PageContianer>
								<ImageViewer dbName={DBName.VDR} />
							</PageContianer>
							{/* <Copyright /> */}
						</PrivateRoute>
						<PrivateRoute
							path={RouterConstants.DEKA_PORTAL.path}
							exact={RouterConstants.DEKA_PORTAL.exact}
							roles={AdminRole()}
							conditions={[hasOrganization]}
						>
							<DekaPage />
						</PrivateRoute>
						<PrivateRoute
							path={RouterConstants.ACCESS_MODELS.path}
							exact={RouterConstants.ACCESS_MODELS.exact}
							roles={AdminRole()}
							conditions={[hasOrganization]}
						>
							<DekaPage />
						</PrivateRoute>
						<PrivateRoute
							path={RouterConstants.ATTRIBUTION_SIMULATOR.path}
							exact={RouterConstants.ATTRIBUTION_SIMULATOR.exact}
							roles={AdminRole()}
							conditions={[hasOrganization]}
						>
							<DekaPage />
						</PrivateRoute>
						<PrivateRoute
							exact={RouterConstants.INSTANCES.exact}
							path={RouterConstants.INSTANCES.path}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.INSTANCE_MANAGEMENT} />
							<PageContianer>
								<InstanceManagement />
							</PageContianer>
							{/* <Copyright /> */}
						</PrivateRoute>

						<PrivateRoute
							exact={RouterConstants.DATASETS_ID.exact}
							path={RouterConstants.DATASETS_ID.path}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.DATASET} />
							<PageContianer>
								<Box>
									<ImageReview dbName={DBName.DATASETS} />
								</Box>
							</PageContianer>
							{/* <Copyright /> */}
						</PrivateRoute>

						<PrivateRoute
							exact={RouterConstants.DATASETS.exact}
							path={RouterConstants.DATASETS.path}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.DATASET} />
							<PageContianer>
								<ImageViewer dbName={DBName.DATASETS} />
							</PageContianer>
							{/* <Copyright /> */}
						</PrivateRoute>

						<PrivateRoute
							exact={RouterConstants.RESEARCH_ID.exact}
							path={RouterConstants.RESEARCH_ID.path}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.RESEARCH} />

							<PageContianer>
								<Box>
									<ImageReviewResearch dbName={DBName.RESEARCH} />
								</Box>
							</PageContianer>
							{/* <Copyright /> */}
						</PrivateRoute>

						<PrivateRoute
							exact={RouterConstants.RESEARCH.exact}
							path={RouterConstants.RESEARCH.path}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.RESEARCH} />
							<PageContianer>
								<ImageViewer dbName={DBName.RESEARCH} />
							</PageContianer>
							{/* <Copyright /> */}
						</PrivateRoute>

						<PrivateRoute
							exact={RouterConstants.ORGANIZATIONS.exact}
							path={RouterConstants.ORGANIZATIONS.path}
							roles={AdminRole()}
						>
							<AppHeader title={"Organization"} />
							<PageContianer>
								<Organization />
							</PageContianer>
						</PrivateRoute>

						{/*<PrivateRoute*/}
						{/*	exact={RouterConstants.ACCOUNT_MANAGEMENT.exact}*/}
						{/*	path={RouterConstants.ACCOUNT_MANAGEMENT.path}*/}
						{/*	roles={ALL_USERS}*/}
						{/*>*/}
						{/*	<Box*/}
						{/*		display="grid"*/}
						{/*		minHeight="100vh"*/}
						{/*		width="100vw"*/}
						{/*		gridTemplateRows="80px auto"*/}
						{/*		key={`gallery-${context.systemView}`}*/}
						{/*	>*/}
						{/*		<ToolHeader isGallery={true} isAccountManagement={true} />*/}
						{/*		<AccountManagement />*/}
						{/*	</Box>*/}
						{/*</PrivateRoute>*/}

						<PrivateRoute
							exact={RouterConstants.USERS.exact}
							path={RouterConstants.USERS.path}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.USERMAMAGEMENT} />
							<PageContianer fullHeight={true}>
								<UserManagement />
							</PageContianer>
							{/* <Copyright /> */}
						</PrivateRoute>

						<PrivateRoute
							exact={RouterConstants.API_BILLING_DASHBOARD.exact}
							path={RouterConstants.API_BILLING_DASHBOARD.path}
							roles={AdminRole()}
						>
							<AppHeader title={SelectedPage.APIBILLINGDASHBOARD} />
							<APIBillingDashboard />
						</PrivateRoute>

						<Redirect to={RouterConstants.HOME.path} />
					</Switch>
				</div>
			</Router>
			<ToastContainer
				style={{
					width: "50%",
					textAlign: "center",
				}}
				toastClassName={classes.toast}
				position="top-center"
				autoClose={3000}
				hideProgressBar
				closeOnClick
				pauseOnHover={false}
				draggable={false}
			/>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		minHeight: "100vh",
		maxWidth: "100vw",
	},
	content: {
		flexGrow: 1,
		overflow: "auto",
		marginTop: 64,
	},
	toolbar: theme.mixins.toolbar,
	toast: {
		backgroundColor: theme.palette.primary.main,
	},
}));

export default Routes;
